<template>
  <v-app>
    <app-navigation></app-navigation>
    <v-main class="secondary">
      <vue-page-transition name="fade-in-left">
        <router-view></router-view>
      </vue-page-transition>

      <WhatsappButton />
    </v-main>
  </v-app>
</template>

<script>
import AppNavigation from "@/components/AppNavigation.vue";
import WhatsappButton from "./components/WhatsappButton.vue";

export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    AppNavigation,
    WhatsappButton,
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;800&display=swap');

$body-font-family: "Open Sans";
$title-font: "Open Sans";

@font-face {
    font-family: 'JMH';
    src: url('@/assets/fonts/JMH\ Typewriter.otf'); /* IE9 Compat Modes */
    src: url('@/assets/fonts/JMH\ Typewriter.ttf')  format('truetype'), /* Safari, Android, iOS */

}


@font-face {
    font-family: 'Betty';
    src: url('@/assets/fonts/Thinking_of_Betty.ttf')  format('truetype'), /* Safari, Android, iOS */

}


@font-face {
    font-family: 'Cocogoose-Compresed';
    src: url('@/assets/fonts/Cocogoose-Compressed-Regular-trial.ttf')  format('truetype'), /* Safari, Android, iOS */

}

@font-face {
    font-family: 'One-Crayon';
    src: url('@/assets/fonts/One Crayon.ttf')  format('truetype'), /* Safari, Android, iOS */

}

.v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}
.v-main__wrap{
  padding-bottom: 300px;
}
@media screen and(max-width: 960px){
  .v-main__wrap{
  padding-bottom: 300px;
}
}



</style>
