import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios'
// Importing the global css file
import "@/assets/global.css"

Vue.config.productionTip = false;

import VuePageTransition from 'vue-page-transition'

Vue.use(VuePageTransition)

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
Vue.use(VuePlyr)

const domain = "https://api.fajardomoretti.com"

const axiosConfig = {
  baseURL: domain + '/api/',
  timeout: 30000,

};
Vue.prototype.$url_domain = domain
Vue.prototype.$axios = axios.create(axiosConfig)


Vue.prototype.$axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    window.location = '/';
    localStorage.clear();
  } else {
    return Promise.reject(error);
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
