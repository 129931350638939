<template>
  <v-menu
    min-width="250"
    top
    v-model="menu"
    :close-on-click="false"
    :close-on-content-click="false"
    :nudge-top="60"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-fab-transition>
        <v-btn
          v-model="fab"
          v-on="on"
          color="primary"
          @click="openClose()"
          dark
          fixed
          bottom
          right
          fab
        >
          <v-icon>{{ icon_wsp }}</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>
    <v-card>
      <v-list dark class="chat-header">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> <v-icon class="pa-0" left>mdi-whatsapp</v-icon>Luis Fajardo</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              @click="
                openClose();
                menu = false;
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-container class="chat-background">
        <v-layout row wrap class="pa-4">
          <v-flex class="text-xs-right" xs12 mb-4>
            <v-chip class="chip-chat">
              Hola, ¿Como puedo ayudarte? <v-icon right>mdi-done</v-icon>
            </v-chip>
          </v-flex>
          <v-flex xs11>
            <v-text-field
              single-line
              color="primary"
              class="my-input"
              v-model="text"
              outline
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs1>
            <v-btn
              class="primary--text rounded-0 px-0 my-4"
              icon
              @click="openClose()"
              :href="apilink"
              target="_blank"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "BlogLuisWhatsappButton",

  data: () => ({
    fab: false,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    phone: "51970080413",
    text: "",
    apilink: "",
    icon_wsp: "mdi-whatsapp",
  }),
  watch: {
    text() {
      this.apilink = "http://";
      this.apilink += this.isMobile() ? "api" : "web";
      this.apilink += ".whatsapp.com/send?phone=" + this.phone + "&text=" + encodeURI(this.text);
    },
  },
  created: function () {
    this.apilink = "http://";
    this.apilink += this.isMobile() ? "api" : "web";
    this.apilink += ".whatsapp.com/send?phone=" + this.phone + "&text=" + encodeURI("Hello!");
  },
  methods: {
    openClose() {
      if (this.fab == true) {
        this.fab = false;
        this.icon_wsp = "mdi-whatsapp";
        this.menu = false;
      } else {
        this.fab = true;
        this.icon_wsp = "mdi-close";
        this.menu = true;
      }
    },
    isMobile() {
      if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        return true;
      }
      if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        return true;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-input.v-input .v-input__slot {
  border-radius: 100px;
}
.chat-background {
  background-color: #ece5dd !important;
}
.chat-header {
  background-color: #22a39f !important;
}
.chip-chat {
  background-color: #dcf8c6 !important;
}
.v-menu__content{
  transition:  all 0.8s ;
}
</style>
