import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [

  { path: '/', component: () => import('../pages/aboutme/Index.vue') ,name:'about-me' },
 
  { path: '/mi-experiencia-profesional', component: () => import('../pages/briefcase/Index.vue') ,name:'brief-case' },
  { path: '/frases', component: () => import('../pages/phrases/Index.vue') ,name:'crisis-phrases' },
  { path: '/contacto', component: () => import('../pages/contact/Index.vue') ,name:'contact' },
  {path:'/detalle/:brief',component: () => import('../pages/briefcase/detail/Index.vue') ,name:'brief-detail' },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
