import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    currentPage: 'about-me',
    title: '',
    photo: '',
    description: '',
    short:'',
    isInteractive:false,
  },
  getters: {
    getCurrentPage(state) {
      return state.currentPage
    },
    getTitle(state){
      return state.title
    },
    getShort(state){
      return state.short
    },
    getPhoto(state){
      return state.photo
    },
    getDescription(state){
      return state.description
    },
    getInteractivePage(state){
      return state.isInteractive
    }

  },
  mutations: {
    setCurrentPage(state, page) {
      state.currentPage = page
    },
    setDetailBrief(state, payload) {
      //console.log(payload)
      state.title = payload.title
      state.photo = payload.photo
      state.description = payload.description
      state.short = payload.short
    },
    saveInteractivePage(state,isInteractive){
      state.isInteractive = isInteractive
    }
  },
  actions: {
    setCurrentPage(context, page) {
      context.commit("setCurrentPage", page)
    },
    setDetailBrief(context, payload) {
      context.commit("setDetailBrief", payload)
    },
    saveInteractivePage(context,isInteractive){
      context.commit("saveInteractivePage", isInteractive)
    }
  },
  modules: {
  },
});
