<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="elevation-0"
      dark
      disable-resize-watcher
      width="80%"
    >
      <v-list>
        <template>
          <v-list-item class="pl-0" v-for="(item, i) in items" :key="i" @click.prevent="goToPage(item.name)">
            <v-list-item-content class="pl-4" :class="{ 'active_2': active_tab == item.name }">
              {{ item.title }}
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>

      <v-list class="d-flex align-center justify-center" style="position:absolute;bottom:0px; width: 100%;">
        <v-btn
        small
        fab
        color="transparent"
        class="elevation-0 mx-1"
        @click.prevent="goToLink('https://instagram.com/luis.carlos.fajardo?igshid=YmMyMTA2M2Y=')"
      >
        <svg
          style="height: 20px !important; width: 20px !important"
          fill="#ffffff"
          xmlns="http://www.w3.org/2000/svg"
          id="uuid-df66ab7e-1411-4739-974b-1f2a4c349c62"
          viewBox="0 0 101.58 102.85"
        >
          <defs></defs>
          <path
            class="uuid-2ee56fc8-b824-45d6-b18e-d6e33b90e377"
            d="M85.92 15.15c0-1.31-1.06-2.37-2.37-2.37H73.6c-1.31 0-2.37 1.06-2.37 2.37v10.58c0 1.31 1.06 2.37 2.37 2.37h9.95c1.31 0 2.37-1.06 2.37-2.37V15.15Zm15.65 8.58v9.5H64.21c-3.75-2.42-8.21-3.83-13-3.83s-9.24 1.41-12.99 3.83H0v-9.5C0 10.62 10.62 0 23.72 0h54.13c13.1 0 23.72 10.62 23.72 23.72M51.23 62.8c5.23 0 9.46-4.23 9.46-9.46s-4.23-9.46-9.46-9.46-9.46 4.23-9.46 9.46S46 62.8 51.23 62.8m50.35 16.33c0 13.1-10.62 23.72-23.72 23.72H23.72C10.61 102.85 0 92.23 0 79.13V42.8h29.71a23.888 23.888 0 0 0-2.44 10.55c0 13.23 10.73 23.96 23.96 23.96s23.96-10.73 23.96-23.96c0-3.79-.88-7.36-2.44-10.55h28.83v36.33Z"
            id="uuid-a24e6069-fdb0-44ad-935c-51d016639b85"
          ></path>
        </svg>
      </v-btn>
      <v-btn
        small
        fab
        color="transparent"
        class="elevation-0 mx-1"
        @click.prevent="goToLink('https://youtube.com/channel/UCI3Ud9mf9Qc3DvzTrAhAuXQ')"
        ><v-icon class="white--text">mdi-youtube</v-icon></v-btn
      >
      <v-btn
        small
        fab
        color="transparent"
        class="elevation-0 mx-1"
        @click.prevent="goToLink('https://www.facebook.com/Luis.Carlos.Fajardo.Moretti/')"
        ><v-icon class="white--text">mdi-facebook</v-icon></v-btn
      >
      <v-btn
        small
        fab
        color="transparent"
        class="elevation-0 mx-1"
        @click.prevent="goToLink('https://twitter.com/FajardoLucho?t=M8CsrHKJbPeTwD9M70xtdw&s=08')"
        ><v-icon class="white--text">mdi-twitter</v-icon></v-btn
      >
      <v-btn
        small
        fab
        color="transparent"
        class="elevation-0 mx-1"
        @click.prevent="
          goToLink('https://www.linkedin.com/in/luis-carlos-fajardo-moretti-a5a5546a')
        "
        ><v-icon class="white--text">mdi-linkedin</v-icon></v-btn
      >
      <v-btn
        small
        fab
        color="transparent"
        class="elevation-0 mx-1"
        @click.prevent="goToLink('http://tiktok.com/@luica1703')"
        ><svg
          style="height: 20px !important; width: 20px !important"
          viewBox="0 0 24 24"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>TikTok</title>
          <path
            fill="#ffffff"
            d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"
          /></svg></v-btn>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app absolute color="gray" class="elevation-0 px-4 ma-0 pa-md-0" :height="height">
      <v-app-bar-nav-icon
        class="hidden-md-and-up white--text"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-container class="wrapper-navigation pa-0 pt-10 d-none d-md-block">
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0 d-flex justify-center">
            <ul class="d-none d-md-inline-flex">
              <li
                v-for="(item, index) in items"
                :key="'items' + index"
                @click.prevent="goToPage(item.name)"
                class="content-tab"
              >
                <v-hover v-slot="{ hover }">
                  <span
                    :class="{ item_hover: hover }"

                    class="item_style active"
                    v-if="item.name === active_tab"
                    >{{ item.title }}</span
                  >
                  <span
                    :class="{ item_hover: hover }"
                    class="item_style"
                    v-else
                    >{{ item.title }}</span
                  >
                </v-hover>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppNavigation",

  data() {
    return {
      appTitle: "Meal Prep",
      drawer: false,
      active_tab: null,
      items: [
        { id: 0, title: "¿Quién soy?", name: "about-me" },
        { id: 1, title: "Mi experiencia", name: "brief-case" },
        { id: 2, title: "¿Sabías que?", name: "crisis-phrases" },
        { id: 3, title: "Contacto", name: "contact" },
      ],
      tab: null,
      height: "100px",
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    //console.log(this.$store.getters["getCurrentPage"]);
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.active_tab = this.$store.getters["getCurrentPage"];
  },

  methods: {
    onResize() {
      if (window.innerWidth < 960) {
        this.height = "80px";
      } else {
        this.height = "100px";
        this.drawer = false;
      }
    },
    goToPage(name) {
      this.active_tab = name
      this.$store.dispatch("setCurrentPage", name);
      this.$router.push({ name: name }).catch(() => {
        /* ignore */
      });
    },
    goToLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-navigation {
  max-width: 900px;
}
.content-tab {
  height: 50px;
  padding: 0px;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  border: none !important;
  cursor: pointer;

}

.active {
  font-weight: bold !important;
}

.item_style {
  font-size: 0.95rem;
  font-weight: bold;
  color: #f3efe0;
}
ul {
  height: 64px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 !important;
}
ul li {
  height: 100%;
  list-style: none;
}

ul li  span {
  display: flex;
  align-items: center;
  height: 47px;
  padding: 0px 20px 0px 20px;
  margin-top: 3px;

}
.item_hover {
  color: white;
  transition: all 0.4s ease 0s;
}
.active {

  box-shadow: 0px 5px 0px 0px white;
  color: white;
}
.active_2{
  box-shadow: 10px 0px 0px 0px white;
  color: white;
  font-weight: bold;
  background-color: grey;
}
</style>
