import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);



export default new Vuetify({

  theme: {
    themes: {
      light: {
        primary: "#22a39f", //"#e0c0a9"
        secondary: "#f3efe0",
        gray:'434242',
        accent: '#075e54',
        error: colors.red.accent3,
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
});
